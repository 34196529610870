.menu {
  background: transparent;
  cursor: pointer;
  border-radius: 50%;
  width: 55px;
  height: 55px; 
  position: absolute;
  right: 14px; 
}

.div-hamburguer { 
  position: absolute;
  right: 0px;
  z-index: 9999;
}

@media (min-width: 767px) {
  .menu-navbar   {
    visibility: hidden;
    background: transparent;
  } 
  .hamburguer {
    visibility: hidden;
    background: transparent;
  } 
  .hamburguer:before {
    visibility: hidden;
    background: transparent;
  } 
  .hamburguer:after {
    visibility: hidden;
    background: transparent;
  } 
}

.hamburguer {
  position: relative;
  display: block;
  background: #1e1e1e;
  width: 32px;
  height: 2px;
  top: 27px;
  left: 14px;
  transition: all 0.4s; 
}

.hamburguer:before,
.hamburguer:after {
  background: #1e1e1e; 
  content: '';
  display: block;
  width: 80%;
  height: 100%;
  position: absolute;
  transition: all 0.4s;
}

.hamburguer:before {
  top: -10px;
}

.hamburguer:after {
  bottom: -10px; 
}

#menu-hamburguer {
  display: none;
}

#menu-hamburguer:checked ~ label .hamburguer {
  transform: rotate(0deg);
  background: transparent;
}

#menu-hamburguer:checked ~ label .hamburguer:before {
  transform: rotate(45deg);
  width: 100%;
  top: 0;
}

#menu-hamburguer:checked ~ label .hamburguer:after {
  transform: rotate(-45deg);
  width: 100%;
  bottom: 0;
} 

