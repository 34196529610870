#blue {
  color: #03a4ed;
}
#red {
  color: #fe3f40;
}

.left-content h6 {
  text-transform: uppercase;
  font-size: 18px;
  color: #fe3f40;
  margin-bottom: 15px;
}
